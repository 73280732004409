import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject, BehaviorSubject, Subscription } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { CommerrorService } from './commerror.service';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';

interface ClinicData {
  status: string;
  hasLeumitCode: boolean;
  // Add any other properties you expect to receive from the server
}
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  contLogin = false;
  username: string;
  limits;
  public premissions = null;
  totpVerify =false;
  public invasiveExams = false;
  UID;
  linksent;
  concentCount;
  base64;
  clinicUsers;
  public loggedIn = false;
  loginError: string;
  getCode = false;
  lastAwl:string;
  lastAwlExam:string;
  lastAwlStartDate:any;
  lastAwlEnDate:any;
  export$ = new Subject<any>()
  exportLoading = false;
  ENVLoaded = new Subject();
  public category = {
    name: "doctor",
    canOpen: {
      form:true,
      exam:true
    },
    canCreate:{
      form:true,
      exam:true
    },
    canChange:{
      form:true,
      exam:true
    },
    canSend: {
      form:true,
      exam:true
    },
    todayList: true
  };
  public hastech = false;
  public Recording: string;
  public RemoteRTC = false;
  public hasAWL = false;
  public AWLUnlimited = false;
  public bitRate = 500000;
  public highBit = 3000000;
  public AWLOnlyExams = false;
  public onLogin: Subject<null>;
  public onFailedLogin: Subject<null>
  public canOpenExams: boolean;
  public fullDelivery: string = 'download';
  public printLang: string = null;
  public printStyle: string = null;
  public userType = 'doctor';
  public summaryOnlyAnamolies = false;
  archiveServer: string;
  loginname:string;
  public printAllMeas = true
  public dopplerAutoOk = true;
  public RequireLoginToOpen = false;
  public anatomyAltNames;
  public hasChameleon = true;
  public hasFollicles=true;
  public defaultsButton = false;
  public links = true;
  public Clinic: string;
  public ClinicID:number;
  public localServer = false;
  public letterhead=false;
  location: string;
  public VideoCodec = 'video/webm;codecs=vp9';
  public clinicOptions;
  public ishospital: any = false;
  public isSheba: any = false;
  public doubleOrgans:string
  public hasLeumit = false;
  public isCardio=false;
  public isGastro=false;
  public PRNcheck = true;
  public requireExamLogon = true;
  public AWLExamNames = false;
  public hasEmergencyRoom = false;
  public altArchiveName:string = null;
  public CustomHeader = new UntypedFormControl(false)
  picAlt="Header Pic"
  headerToShow;
  isImageLoading = false;
  public altSplashPage;
  public isAdmin= false;
  public noneAsMeasDefault = false;
  public LeumitAdmin = false;
  public barcodeScan = false;
  public ExamCodes;
  public hasENV = false;
  public doctor = null;
  public PeerServ: {host: string, port: number, path: string};
  public separatedClinic = false;
  public SeparateZimun = false;
  public defaultExams;
  public hasSurrogatePreg = false;
  public lmp_in_mwl = false;
  public hasImaging = false;
  public APIversion:string;
  public usMode=false
  private sessionExpiration = new BehaviorSubject<string>('');
  private sessionExpirationSubscription: Subscription;
  private timeoutId: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private sanitization: DomSanitizer,
    private commerrorService: CommerrorService,
    private translate: TranslateService,
    private dateAdapter: DateAdapter<any>,
  ) {
      this.onLogin= new Subject();
      this.onFailedLogin = new Subject();
      this.http.get('/api/getenv').subscribe((data)=>{
        let p = {};
        p['env'] = data;
        this.setEnv(p);
      },(error)=> { 
        console.log(error);
      })

      // this.sessionExpirationSubscription = this.sessionExpiration.asObservable().subscribe(expiration => {
      //   this.handleSessionExpiration(expiration);
      // });
   }

  //  ngOnDestroy() {
  //   if (this.sessionExpirationSubscription) {
  //     this.sessionExpirationSubscription.unsubscribe();
  //   }
  //   clearTimeout(this.timeoutId);
  // }

  login(user: string, pw: string, navigate = true, exam = false, isexport = false,code=undefined) {
    if(! exam){
      // if(this.ishospital){
      //   this.localServer=false;
      //   let req = this.http.post("/api/login",{"user": user,"pw": pw,"inside": exam, code})
      //   if(isexport)
      //     this.followLogin(req,navigate,user, true);
      //   else
      //     this.followLogin(req,navigate,user);
      // }
      // else{
        this.http.get('http://localhost:8088/alive').subscribe((data)=>{
          if(data['status'] == 'alive')
            this.localServer = true;
            this.http.get('http://localhost:8088/whereami').subscribe((data)=>{
              if(data['office_server']){
                this.ldapLogin(user,pw, data,navigate);
                return;
              }
              if(data['location']){
                let req = this.http.post("/api/login",{"user": user,"pw": pw,"inside": exam, "location": data['location'], code})
                this.followLogin(req,navigate,user);
              }
            },(error)=>{
              let req = this.http.post("/api/login",{"user": user,"pw": pw,"inside": exam, code})
              this.followLogin(req,navigate,user);
              console.log(error);
            });
        }, (error)=>{
          this.localServer=false;
          let req = this.http.post("/api/login",{"user": user,"pw": pw,"inside": exam, code})
          this.followLogin(req,navigate,user);
        });
      // }
    }
    
    
  }


  followLogin(req, navigate = true,user=null, isexport = false){
    this.clinicOptions = null;
    req.subscribe((data)=>{
      if(data.hasOwnProperty("status") && data["status"] == "ok" && ! data.hasOwnProperty('code')){
        if(isexport){
          this.export$.next(this.exportData())
          return;
        }
        this.Clinic = null;
        if(data['Clinic']){
          this.Clinic = data['Clinic'];
          //try local server
          
        }    
        this.loginError = null;
        this.loginname = user;
        this.UID = data['uid'];
        this.username=data["uname"];
        this.LeumitAdmin = data['HMO'];
        this.totpVerify = data['TOTPSet'];
        this.separatedClinic = false;
        if(data['hasSeparation'])
          this.separatedClinic = true;
        if(data['SeparateZimun'])
          this.SeparateZimun = true;
        else
          this.SeparateZimun=false;
        this.hasSurrogatePreg = data['hasSurrogatePreg'];
        this.lmp_in_mwl = false;
        if(data['lmp_in_mwl'])
          this.lmp_in_mwl = true;
        
        if(data["category"]){
          switch (data["category"]) {
            case "forms":
              this.category={
                name: data["category"],
                canOpen: {
                  form:true,
                  exam:false
                },
                canCreate:{
                  form:true,
                  exam:false
                },
                canChange:{
                  form:true,
                  exam:false
                },
                canSend: {
                  form:true,
                  exam:false
                },
                todayList: true
              }
              break;
              case "tech":
                this.category={
                  name: data["category"],
                  canOpen: {
                    form:true,
                    exam:true
                  },
                  canCreate:{
                    form:true,
                    exam:true
                  },
                  canChange:{
                    form:true,
                    exam:true
                  },
                  canSend: {
                    form:true,
                    exam:true
                  },
                  todayList: true
                }
                break;
            case "secretary":
              this.category = {
                name: data["category"],
                canOpen: {
                  form:false,
                  exam:false
                },
                canCreate:{
                  form:false,
                  exam:false
                },
                canChange:{
                  form:false,
                  exam:false
                },
                canSend: {
                  form:false,
                  exam:true
                },
                todayList: true
              }
              break;
            case "nurse":
              this.category = {
                name: data["category"],
                canOpen: {
                  form:true,
                  exam:true
                },
                canCreate:{
                  form:true,
                  exam:false
                },
                canChange:{
                  form:true,
                  exam:false
                },
                canSend: {
                  form:true,
                  exam:true
                },
                todayList: true
              }
              break;
            case "doctor":
              this.category = {
                name: data["category"],
                canOpen: {
                  form:true,
                  exam:true
                },
                canCreate:{
                  form:true,
                  exam:true
                },
                canChange:{
                  form:true,
                  exam:true
                },
                canSend: {
                  form:true,
                  exam:true
                },
                todayList: true
              }
              break;        
            default:
              this.category = {
                name: data["category"],
                canOpen: {
                  form:true,
                  exam:true
                },
                canCreate:{
                  form:true,
                  exam:true
                },
                canChange:{
                  form:true,
                  exam:true
                },
                canSend: {
                  form:true,
                  exam:true
                },
                todayList: true
              }
              break;
          }
        }
        if(! this.ishospital)
          this.getLimits();
        if(data["membership"])
          this.userType = data["membership"];  
        else{//no login
          if(data['env']['requiregroup']){
            this.loginError = "User is not authorized to use this system";
            return;
          }
          this.userType = 'doctor';//otherwise assume doctor
        }
        // this.isAdmin = data['isAdmin'];
        this.isAdmin = data['isAdmin'] || this.userType=='admin';
        this.clinicUsers = data['users'];
        if(this.userType == 'doctor' && this.separatedClinic)
          this.doctor = this.UID;
        this.canOpenExams = data["openExams"];
        if(data['clinics']){
          this.clinicOptions = data['clinics'];
        }
        else{
          this.onLogin.next();
          if(navigate)
            this.router.navigate(['/patsearch']); 
           
        } 
      }
      if(data.hasOwnProperty('letterhead'))
        this.letterhead = data['letterhead'];
      else
        this.letterhead = false;
    this.requireExamLogon = data['requireExamLogon'];
    this.setEnv(data);
    if(data['GID'] && ! this.ishospital){
      this.ClinicID = data['GID'];
   
      this.http.get(`/api/login/getTOS`).subscribe((data) => {
        this.premissions = data[0];
        this.setInterfaceLanguage(this.premissions?.interfaceLanguages)
      })
   
    
    }
      if(data.hasOwnProperty('code')){
        this.getCode=true;
      }
      if(data.hasOwnProperty("error")){
        this.loginError = data["error"]
      }
      this.contLogin = false;
    },(error)=>{
      this.loginError = error.error.error;
      this.onFailedLogin.next();
    });
    return req
  }

  setEnv(data){
    this.ishospital = data['env']['ishospital'];
    if(data["env"]){
      if( data["env"]["recording"]){
        this.Recording = data["env"]["recording"];
        if(data["env"]["webrtc"] == "remote"){
          this.RemoteRTC = true;
          this.PeerServ = data["env"]["peerServer"];
        }
      }
      if(this.ishospital ){
        this.premissions = data["env"]["premissions"]
        this.invasiveExams = this.premissions.ObstetricInvasive;
        this.defaultExams = null;
        if(data.defaultExams)
          this.defaultExams = data.defaultExams;
          if(data['env']['usMode']){
            this.usMode = data['env']['usMode'];
          }  
      }
      if(data['env']['isSheba']){
        this.isSheba = data['env']['isSheba']
      } if(data['env']['doubleOrgans']){
        this.doubleOrgans = data['env']['doubleOrgans']
      }
      if(data['env']['hasTech'])
        this.hastech = data['env']['hasTech'];
      if(data['env']['bitRate'])
        this.bitRate = data['env']['bitRate'];
      if(data['env']['highBitrate'])
        this.highBit = data['env']['highBitrate'];
      if(data["env"]["AWL"])
        this.hasAWL = true;
      if(data["env"].hasOwnProperty("AWLOnlyExamOpening"))
        this.AWLOnlyExams = data["env"]['AWLOnlyExamOpening'];
      if(data["env"]["printLang"])
        this.printLang = data["env"]["printLang"];
      if(data["env"]["printStyle"])
        this.printStyle = data["env"]["printStyle"];
      if(this.ishospital)
        this.RequireLoginToOpen = true;
      else{
        if(data["env"]["RequireLoginToOpen"])
          this.RequireLoginToOpen = data["env"]["RequireLoginToOpen"];
      }
      if(data["env"]["FullDelivery"])
        this.fullDelivery = data["env"]["FullDelivery"];
      if(data["env"]["AWLUnlimitedExams"])
        this.AWLUnlimited = data["env"]["AWLUnlimitedExams"];
      if(data["env"].hasOwnProperty('dopplerAutoOK'))
        this.dopplerAutoOk = data["env"]["dopplerAutoOK"];
      if(data["env"].hasOwnProperty("hasChameleon"))
        this.hasChameleon = data["env"]["hasChameleon"];
      if(data['env'].hasOwnProperty('hasFollicles'))
        this.hasFollicles = data['env']['hasFollicles'];
      if(data['env']['videoCodec'])
        this.VideoCodec = data['env']['videoCodec'];
      if(data['env']['archiveserv'])
        this.archiveServer = data['env']['archiveserv'];
      if(data['env']['anomaliesInSummary'])
        this.summaryOnlyAnamolies = data['env']['anomaliesInSummary'];
      if(data['env']['altNames'])
        this.anatomyAltNames = data['env']['altNames'];
      if(data['env']['defaultsButton'])
        this.defaultsButton = data['env']['defaultsButton'];
      if(data['env'].hasOwnProperty('smslink'))
        this.links = data['env']['smslink'];
      if(data['env'].hasOwnProperty('noprncheck'))
        this.PRNcheck = ! data['env'].noprncheck;
      if(data['env']['altSplash'])
        this.altSplashPage = data['env']['altSplash'];
      if(data['env']['hasEmergencyRoom'])
        this.hasEmergencyRoom = true;
      if(data['env']['awlExamNames'])
        this.AWLExamNames = data['env']['awlExamNames'];
      if(data['env']['altArchiveName'])
        this.altArchiveName = data['env']['altArchiveName'];
      if(data['env'].hasOwnProperty('measDefaultNone'))
        this.noneAsMeasDefault = data['env']['measDefaultNone'];
      if(data['env']['barcodeScan'])
        this.barcodeScan = data['env']['barcodeScan'];
      if(data['env']['examCodes'])
        this.ExamCodes = data['env']['examCodes'];
      if(data['env']['version'])
        this.APIversion = data['env']['version'];
    }
    if(data.hasOwnProperty('hasLeumit'))
      this.hasLeumit = data.hasLeumit;
      this.isCardio = data.isCardio;
    // if(data['isCardio'])
    // else
    //   this.isCardio = false;
    if(data['isGastro'] || this.premissions)
      this.isGastro = this.premissions ? this.premissions.Gastro : data.isGastro
    else
      this.isGastro = false;
    if(this.isCardio && this.userType){
        switch(this.userType){
          case 'doctor':
            this.lastAwl = 'Completed';
          break;
          case 'tech':
            this.lastAwl = 'Received';
          break;
          case 'secretary':
            this.lastAwl = 'Waiting';
          break;
        }
    }
    if(data['hasImaging'])
      this.hasImaging = data['hasImaging'];
    this.ENVLoaded.next();
    this.hasENV = true;
  }


  // getSessionExpiration() {
  //   return this.sessionExpiration.asObservable();
  // }

  // updateSessionExpiration(newExpiration: string) {
  //   this.sessionExpiration.next(newExpiration);
  // }

  // private handleSessionExpiration(expiration: string) {
  //   const expirationTime = new Date(expiration).getTime();
  //   const currentTime = Date.now();
  //   const timeLeft = expirationTime - currentTime;

  //   // Clear previous timeout if any
  //   clearTimeout(this.timeoutId);

  //   if (timeLeft > 10000) { // more than 10 seconds left
  //     this.timeoutId = setTimeout(() => {
  //       this.commerrorService.errorSession('Session About To Expire', 'Your session will expire soon')
  //       console.log('Your session is about to expire.');
  //     }, timeLeft - 10000); // Set timeout to trigger 10 seconds before expiration
  //   }
  // }


  sendCode(code: string){
    let req = this.http.get("api/logincode",{params:{"code": code}});
    req.subscribe((data)=>{
      if(data.hasOwnProperty("status") && data["status"] == "ok"){
        this.loginError = null;
        this.username=data["uname"];
        this.getCode=false;
         this.router.navigate(['/patsearch']);  
      }
      if(data.hasOwnProperty("error")){
        this.loginError = data["error"]
      }
    },(error)=>{
      this.loginError = error.error.error;
    });
  }

  public getUserName(){
    return this.username
  }

  loginAsCurrentUser(){
    if(this.username && ! this.requireExamLogon)
      this.onLogin.next();
  }

  ldapLogin(user:string,pw: string, serv,navigate=false){
    this.http.post('http://localhost:8088/login',{login: user, 'pw': pw}).subscribe((data)=>{
      if(data.hasOwnProperty("error")){
        this.loginError = data["error"]
      }
      else{
        if(serv['location'])
          data['location'] = serv['location'];
        this.followLogin(this.http.post('/remote/login',data),navigate,user);
      }
    },(err)=>{
      console.log(err);
      this.loginError = err.error.error;
    })
  }

  public getArchiveServer():string{
    return this.archiveServer;
  }

  public async Logout() {
    (await this.clearCookies()).subscribe((data) => {
      this.router.navigate([""]);
      this.export$.next(false);
      this.totpVerify = false;
    })
  }

  public canStartExam(hasawl: boolean):boolean{
    return this.canOpenExams && (this.ishospital || this.localServer) && (this.userType == 'doctor' || this.userType== 'tech') &&(! this.AWLOnlyExams ||  (this.AWLOnlyExams && hasawl));
  }

  public loginMissing(){
    if(! this.username)
      this.router.navigate(['/']);
  }

  public showAWL():boolean{
    return this.hasAWL && (! this.ishospital || this.userType == 'doctor' || this.userType == 'tech' || this.userType == 'secretary');
  }

  setClinic(id:number){
    this.http.get('/api/setclinic/'+ this.clinicOptions[id].GID).subscribe((data:ClinicData)=>{
      this.ClinicID = this.clinicOptions[id].GID;
      this.Clinic = this.clinicOptions[id].ClinicTitle;
      this.hasLeumit = data.hasLeumitCode;
      if(this.clinicOptions[id].MarginTop || this.clinicOptions[id].MarginBottom)
        this.letterhead = true;
      this.clinicOptions = null;
      this.loginError = null;
      this.http.get(`/api/login/getTOS`).subscribe((data) => {
        this.premissions = data[0];
          this.router.navigate(['/patsearch']);  
  
        
      })
      this.onLogin.next();
    },(error)=>{
      this.loginError = error;
      this.onFailedLogin.next();
    })
  }
  getUserType(){
    return this.userType;
  }

  saveHeader(formData){
    this.http.post(`/api/NewExams/setHeader/${this.CustomHeader.value ? 'y' : 'n'}`,formData).subscribe((data) => {
      console.log(data);
    })
  }

  getHeader(){
    this.http.get('/api/NewExams/getHeader' ).subscribe((data)=>{
      if(data['CustomLogo']){
        this.CustomHeader.patchValue(data['CustomLogo'])
      }
      if((data['PrintHeader'] || data['PrintHeaderCustom'] ) && ! this.ishospital && ! this.isCardio)
        this.getImageFromService();
    })
  }
  chaneHeader(){
    this.http.post('/api/NewExams/chageHeaderSetting', {CustomLogo: this.CustomHeader.value}).subscribe()
  }

  getImage(): Observable<Blob> {
    if(this.ishospital){
      if(this.CustomHeader.value){
        this.headerToShow = `../../assets/images/${this.getUserName()}.jpg`
      }else{
        this.headerToShow = `../../assets/images/clinic.jpg`
      }
      
    }else{
      if(this.CustomHeader.value){
        return this.http.get(`/api/NewExams/getImage/y`, { responseType: 'blob' });
      }else{
        return this.http.get(`/api/NewExams/getImage/n`, { responseType: 'blob' });
      }
    }
  }
  createImageFromBlob(image: Blob) {
    // this.headerToShow = this.sanitization.bypassSecurityTrustUrl(URL.createObjectURL(image))
    let reader = new FileReader();
    reader.addEventListener("load", () => {
       this.base64 = reader.result;
      // this.headerToShow = reader.result;
      this.headerToShow = this.sanitization.bypassSecurityTrustUrl(this.base64)

    }, false);
 
    if (image) {
       reader.readAsDataURL(image);
    }
 }
 getImageFromService() {
  this.isImageLoading = true;
  if(this.ishospital){
    if(this.CustomHeader.value){
      this.headerToShow = `../../assets/images/${this.getUserName()}.jpg`
    }else{
      this.headerToShow = `../../assets/images/clinic.jpg`
    }
  }
}

  exportData(){
    this.exportLoading = true;
    return this.http.get('/api/stats/exportdata')
  }

  getTOTP(uname, pw){
    return this.http.post('/api/login/getTOTPSet', {uname:uname, pw:pw})
  }

  getDocByUID(uid:number){
    for(let usr of this.clinicUsers){
      if(usr.UID == uid)
        return usr.Name;
    }
    return '';
  }

  getLimits(){
    this.http.get('/api/limits').subscribe((data) =>{
      this.limits = data;
    })
  }

  countLinks(){
    this.http.get('/api/NewExams/countLinks').subscribe((data:any) =>{
      this.linksent = data;
    })
  }

  
  countConcent(){
    this.http.get('/api/NewExams/countConcent').subscribe((data:any) =>{
      this.concentCount = data;
    })
  }

  async clearCookies() {
    return this.http.get("/api/login/clearCookies");
  }

  public setInterfaceLanguage(language: string) {
    switch (language) {
      case 'English':
        this.translate.use('en-US');
        this.dateAdapter.setLocale('en-US'); // Set locale for DatePicker
        break;
      case 'Hebrew':
        this.translate.use('he-IL');
        this.dateAdapter.setLocale('he-IL');
        break;
      case 'Polish':
        this.translate.use('pl-PL');
        this.dateAdapter.setLocale('pl-PL');
        break;
      case 'Spanish':
        this.translate.use('es-ES');
        this.dateAdapter.setLocale('es-ES');
        break;
      case 'Italian':
        this.translate.use('it-IT');
        this.dateAdapter.setLocale('it-IT');
        break;
      default:
        this.translate.use('en-US');
        this.dateAdapter.setLocale('en-US');
        break;
    }
  }
}
